
































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import ImportExcel from "@/components/ImportExcel/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

import {
  CustomFormType,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  OrganizationUnitCreateOrUpdateDto,
  OrganizationUnitDto,
  StarRatingApplyDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    AbSelect,
    ImportExcel,
    AgileAuditTag,
    ExportButton,
  },
})
export default class OrganizationList extends Vue {
  @Ref() readonly pagedTableView!: any;
  dataId?: number;
  volunteerId = 0;
  tableItems: StarRatingApplyDtoPagedResultDto[] = [];

  centerDialogVisible = false;

  queryForm: any = {
    surname: undefined,
    phoneNumber: undefined,
    starRatingId: undefined,
  };

  orgTypeList: DataDictionaryDto[] | undefined = [];

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.queryForm.starRatingId = this.dataId;
    }
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.starRatingApply.getAll({
      ...params,
      starRatingId: this.dataId,
    });
  }

  // formatOrganizationType(value: any, column: any, cellValue: any) {
  //   switch (cellValue) {
  //     case "Partner":
  //       return "合作方";
  //
  //     case "School":
  //       return "学校";
  //
  //     default:
  //       return "未知";
  //   }
  // }

  async getCustomFormId(hostId: string | undefined, callBack: any) {
    // await api.customFormService
    //   .getCustomFormId({
    //     hostType: CustomFormType.OrganizationUnit,
    //     hostId: hostId,
    //   })
    //   .then((res) => {
    //     callBack(res);
    //   });
  }

  // 新建
  // handleCreate(id: number) {
  //   this.getCustomFormId(id.toString(), (formId: string) => {
  //     if (formId) {
  //       this.$router.push({
  //         name: "organizationCreate",
  //         query: {
  //           formId: formId,
  //           hostId: id.toString(),
  //         },
  //       });
  //     } else {
  //       this.$confirm("暂无表单，是否前往创建?", "提示", {
  //         confirmButtonText: "确定",
  //         cancelButtonText: "取消",
  //         type: "warning",
  //       })
  //         .then(() => {
  //           this.$router.push({
  //             name: "partnerCustomForm",
  //           });
  //         })
  //         .catch(() => {
  //           this.centerDialogVisible = false;
  //         });
  //     }
  //   });
  // }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "research": {
        this.handleResearch(e.index, e.item);
        return;
      }
    }
  }

  // 查看志愿者详情
  jumpDetail(index: number, row: any) {
    this.volunteerId = row.id!;
    this.$router.push({
      name: "VolunteerDetail",
      params: { id: row.volunteerId!.toString() },
    });
  }
}
