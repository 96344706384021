












































































































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import ImportExcel from "@/components/ImportExcel/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

import {
  CustomFormType,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  OrganizationUnitCreateOrUpdateDto,
  OrganizationUnitDto,
  OrganizationUnitDtoPagedResultDto,
  AuditFlowScope
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";
@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    AbSelect,
    ImportExcel,
    AgileAuditTag,
    ExportButton
  },
})
export default class OrganizationList extends Vue {
  @Ref() readonly pagedTableView!: any;
  dataId?: number;
  editId = 0;
  tableItems: OrganizationUnitDtoPagedResultDto[] = [];

  centerDialogVisible = false;

  queryForm: any = {
    volunteerOrgCode: undefined,
    surname: undefined,
    starRatingTitle: undefined,
    creationTime: undefined,
    typeId: undefined,
    searchStatus:undefined,
    status: undefined,
    starRatingId: undefined
  };

  evaluationTypeList: DataDictionaryDto[] = [];
  statusList: any = [];

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.queryForm.starRatingId = Number(this.$route.params.id)
      this.fetchDataDictionary();
    }

  }
  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: 'EvaluationType',
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.evaluationTypeList = res!.items!;
      });

    //状态
    await api.enumService.getValues({
      typeName: 'StarRatingRecordStatus'
    })
    .then((res) => {
      this.statusList = res!;
    })
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.starRatingRecord.getAll({
      ...params,
      starRatingId: this.dataId });
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.StarRatingRecord,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "research": {
        this.handleResearch(e.index, e.item);
        return;
      }
    }
  }

  // 去审核
  // handleAudit(index: number, row: OrganizationUnitDto) {
  //   this.getCustomFormId(String(row!.organizationType), (formId: string) => {
  //     this.$router.push({
  //       name: "organizationEdit",
  //       params: { id: row.id!.toString() },
  //       query: {
  //         formId: formId,
  //       },
  //     });
  //   });
  // }

  //重置
  handleReset(item: number, row: any) {
    this.$confirm("您确定重置该评定记录吗?", "提示").then(() => {
      api.starRatingRecord.reset({ body:{ id: row.id }}).then((res) => {
        this.$message.success("重置成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  // 查看详情
  jumpDetail(index: number, row: any) {
    this.editId = row.id!;
    this.$router.push({
      name: "recordAndPublicity-record-detail",
      params: { id: row.id!.toString() },
    });
  }

  // 审核
  handleAudit(index: number, row: any) {
    this.editId = row.id!;
    this.$router.push({
      name: "recordAndPublicity-record-detail",
      params: { id: row.id!.toString() },
      query:{
        isAudit:'true'
      }
    });
  }
}
