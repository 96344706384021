










































































































import {Component, Vue} from "vue-property-decorator";
import {
  TrainMethod,
  AttachmentHostType, LecturerDto, TrainDto, StarRatingDto, DataDictionaryDto
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "LecturerDetail",
  components: {
    AttachmentsView,
  },
  filters: {
    formatStatus(status?: string) {
      switch (status) {
        case "Draft":
          return "草稿";

        case "Published":
          return "已发布";

        case "Deleted":
          return "已删除";
      }
    },
  },
})
export default class StarRatingDetail extends Vue {
  dataId?: number;
  detail: StarRatingDto = {
    type: {
      displayName: undefined
    },
    manager: {
      surname: undefined
    }
  };
  baseURL: any;
  loading = true;
  attachments: any = [];
  starLevelList: DataDictionaryDto[] = [];

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await this.fetchDetail();
      await this.loadAttachments();
      this.loading = false;
    }
    this.fetchStarLevelList();
  }

  getTime(item: any) {
    return moment(item).format("HH:mm");
  }

  // 获取详情
  async fetchDetail() {
    await api.starRating.get({id: this.dataId}).then((res) => {

      this.detail = {...res};
    });
  }

  async loadAttachments() {
    this.loading = false;
    await api.attachmentService
      .getAttachments({
        hostId: this.dataId!.toString(),
        hostType: AttachmentHostType.StarRating
      })
      .then((res) => {
        this.loading = true;
        this.attachments = res;
      });
    console.log(this.attachments);
  }

  //下载附件
  handleDownload(item: any) {
    // api.attachment.download(item.id);
    if (this.isImage(item)) {
      window.open(item.url, "_blank");
    } else {
      const downloadUrl = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
      window.open(downloadUrl, "_blank");
    }
  }

  isImage(item: any) {
    if (!item.extension) {
      return false;
    }
    let ext = "";
    if (item.extension.substr(0, 1) === ".") ext = item.extension.substr(1);

    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext.toLowerCase());
  }

  async fetchStarLevelList() {
    await api.dataDictionary.getDataDictionaryListByKey({key: 'VolunteerLevel'}).then(res => {
      this.starLevelList = res.items ?? []
    })
  }

  getStarLevelText(level: number) {
    let starLecelText = ''
    for (let i in this.starLevelList) {
      if (this.starLevelList[i].displayOrder === level) {
        starLecelText = this.starLevelList[i].displayName ?? ''
      }
    }
    return starLecelText;

  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
