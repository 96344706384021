














































































































































































































import {Component, Ref, Vue} from "vue-property-decorator";
import {ElForm} from "element-ui/types/form";
import {
  AttachmentHostType, CmsAnchorDto,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  StarRatingStatus, TrainDto,
} from "@/api/appService";
import api from "@/api";
import multipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleVideoUpload from "@/components/SimpleVideoUpload/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";
import UserSelect from "@/components/UserSelect/index.vue"

import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import {CodeToText, regionData} from "element-china-area-data";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import SelectTrainList from "@/views/starLevelEvaluate/maintain/components/selectTrainList.vue";

Vue.use(regionData, CodeToText)

@Component({
  components: {
    SelectTrainList,
    AttachmentsView, SimpleVideoUpload, SimpleUploadImage, multipleUploadFile, Ueditor, BaiduMap, UserSelect
  }
})

export default class EditCmsContent extends Vue {
  @Ref() readonly dataForm!: ElForm;
  editId?: number = 0;
  submitting = false;
  starRatingTypeArray: DataDictionaryDto[] = [];
  type: any = {}
  starLevelList: DataDictionaryDto[] = [];
  managerList: any = [];
  trainList: TrainDto[] = [];
  trainIds: any = [];
  form: any = {
    id: 0,
    applyEndTime: '',
    writtenEndTime: '',
    reportSubmitEndTime: '',
    interviewTime: '',
    showDiglog: false,
    titleImagePath: undefined,
    title: undefined,
    content: undefined,
    typeId: undefined,
    host: undefined,
    minLevel: undefined,
    minPoint: undefined,
    minServiceHours: undefined,
    trainCount: undefined,
    serviceCount: undefined,
    activityCount: undefined,
    status: StarRatingStatus.Saved,
    trainIds: undefined,
    manager: {
      surname: undefined
    }
  };

  loading = true;

  data() {
    return {
      activeNames: ['1']
    }
  }

  get hostTypeFile() {
    return AttachmentHostType.StarRating;
  }

  get starRatingFiles() {
    return AttachmentHostType.StarRating;
  }

  showSelectTrainDialog() {
    (this.$refs.selectTrainList as any).show = true;
  }

  created() {
    //
    if (this.$route.params.id) {
      this.editId = Number(this.$route.params.id);
      // this.fetchDetail();
      api.starRating.get({id: this.editId}).then(res => {
        this.form = {...res};
        console.log(this.form.trainIds);
        if (res.trainList && res.trainList.length > 0) {
          this.trainList = res.trainList;
        }
        this.loading = false;
      })
    }
    this.fetchDictionary()
    this.getManagerList();
  }

  // 获取详情
  // async fetchDetail() {
  //   await api.starRating.get({id: this.editId}).then(res => {
  //     this.form = {...res};
  //   })
  // }

  async fetchDictionary() {
    //星级评定类型
    await api.dataDictionary.getDataDictionaryListByKey({
      maxResultCount: 65535,
      key: 'EvaluationType'
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.starRatingTypeArray = res.items!;
    });
    //志愿者星级
    await api.dataDictionary.getDataDictionaryListByKey({
      maxResultCount: 65535,
      key: 'VolunteerLevel'
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.starLevelList = res.items!;
    });
  }

  async getManagerList() {
    let _this = this as any
    await api.user.getUserListByRoleName({roleName:"PlatformAdministrator"}).then((res) => {
      _this.managerList = res!
      let newobj = {} as any;
      _this.managerList = _this.managerList.reduce((preVal: any, curVal: any) => {
        newobj[curVal.surname] ? '' : newobj[curVal.surname] = preVal.push(curVal);
        return preVal
      }, [])
    })
  }

  // 返回
  private cancel() {
    this.$router.back();
  }


  setTableData(trainList: TrainDto[]) {
    this.trainList = trainList;
  }

  handleDelete(index: number, row: TrainDto) {
    this.trainList.splice(index, 1)
  }

  handleClear() {
    this.trainList = [];
  }

  private async save(isPublish: boolean) {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (!this.form.content) {
          this.$message({
            type: 'error',
            message: '请填写星级评定详情'
          })
          return
        }
        this.trainIds = [];

        this.trainList.forEach((res) => {
          if (res && res.id) this.trainIds.push(res.id)
        })
        this.form.trainIds = this.trainIds.toString();
        console.log(this.trainIds)
        if (isPublish) {
          this.form.status = StarRatingStatus.Published;
        } else {
          this.form.status = StarRatingStatus.Saved;
        }

        (this as any).$bus.$emit('before-save');
        this.submitting = true
        let fn = undefined
        if (this.form!.id) {
          fn = api.starRating.update
        } else {
          fn = api.starRating.create
        }
        await fn({body: this.form}).then(res => {
          this.$message({
            type: "success",
            message: "操作成功"
          });
          setTimeout(() => {
            this.$router.push({name: "maintain"});
          }, 200);
        }).catch(() => {
          this.submitting = false
        });
      } else {
        this.$message({
          type: 'error',
          message: '请检查表单'
        })
      }
    });
  }

  roleRule = {
    title: [{
      required: true,
      message: '请填写主题',
      trigger: 'blur'
    }],
    content: [
      {
        required: true,
        message: "请填写星级评定详情",
        trigger: "blur"
      }
    ],
    typeId: [
      {
        required: true,
        message: "请选择评定类型",
        trigger: "blur"
      }
    ],

    managerId: [
      {
        required: true,
        message: "请选择负责人",
        trigger: "blur"
      }
    ],

    titleImagePath: [
      {
        required: true,
        message: '请上传封面图片',
        trigger: 'blur'
      }
    ],

    // applyEndTime: [{
    //   required: true,
    //   message: '请填写报名截止时间',
    //   trigger: 'blur'
    // }],

    // filePath: [{
    //   required: true,
    //   message: '请上传附件',
    //   trigger: 'blur'
    // }],
  };
}
