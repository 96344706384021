






























































































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  CustomFormType,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  StarRatingCreateOrUpdateDto,
  StarRatingDto,
  StarRatingManagementCreateOrUpdateDto,
  TrainDto, TrainManagementCreateOrUpdateDto
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";

// Vue.use(regionData, CodeToText);

@Component({
  components: {
    AbSelect,
    Ueditor,
    CustomFormEdit,
    CustomFormDataTableDetail
  },
})
export default class CreateServiceOrganization extends Vue {
  @Ref("customForm") customForm!: CustomFormEdit;

  @Ref() readonly dataForm!: ElForm;
  dataId = 0;
  dataText = '修改';
  customFormId = 0;
  defaultData: StarRatingManagementCreateOrUpdateDto = {};
  isDetail = false;
  isFormReady = false;
  form: StarRatingManagementCreateOrUpdateDto = {
    extensionObject: {}
  };
  starRatingInfo: StarRatingDto = {
    type: {},
    manager: {}
  }

  starRatingList: StarRatingDto[] = [];
  submitting = false;
  roleRule = {
    starRatingId: [
      {
        required: true,
        message: "主题必填",
        trigger: "blur",
      },
    ],
  };

  getStarRatingDetail() {
    api.starRating
      .get({ id: this.form.starRatingId })
      .then((res) => {
        this.starRatingInfo = res;
        this.fetchFormDetail();
        });
  }

  getStarRatingList() {
    api.starRating.getAll({ maxResultCount: 65535 }).then((res) => {
      this.starRatingList = res.items!;
    });
  }

  async fetchFormDetail() {
    const formId = await api.customFormService.getCustomFormId({
      hostType: this.hostType,
      hostId: "337",
    });

    this.custogetmFormId = parseInt(formId, 10);
    this.isFormReady = true;
  }

  get getData() {
    if (this.dataId > 0)
      return api.starRatingManagement.get({ id: this.dataId });
    else return this.defaultData;
  }

  get hostType() {
    return CustomFormType.StarRatingManagement;
  }

  get titleDisabled() {
    return this.dataId > 0;
  }

  // 保存
  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid && !this.isDetail) {
        (this.$refs.customForm as any).validate(async (valid2: boolean) => {
          if (valid2) {
            const data = (
              this.customForm as any
            ).getRequestData() as StarRatingManagementCreateOrUpdateDto;
            data.extensionData = JSON.stringify(data.extensionObject);
            debugger;
            let submitForm: StarRatingManagementCreateOrUpdateDto = {
              starRatingId: this.form.starRatingId,
              extensionObject: data.extensionObject,
              extensionData: data.extensionData,
              id: this.dataId,
            };

            let fn;
            if (this.dataId) fn = api.starRatingManagement.update;
            else fn = api.starRatingManagement.create;

            fn({ body: submitForm }).then((res) => {
              if (this.dataId) {
                this.$message.success("更新成功");
              } else {
                this.$message.success("新增成功");
              }
              this.$router.push({
                name: "summary-table",
              });
            });
          } else {
            this.$message.error("请检查表单");
          }
        });
      } else {
        this.$message.error("请检查表单");
      }
    });
  }

  async created() {
    await this.getStarRatingList();

    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      if (this.$route.params.isDetail){
        this.dataText = '详情'
      }
      api.starRatingManagement.get({ id: this.dataId }).then((res) => {
        this.form = res;
        this.getStarRatingDetail();
      });
    }

    if(this.$route.params.isDetail) {
      this.isDetail = true;
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  //返回
  cancel() {
    this.$router.back();
  }

}
