









































































































































import api from "@/api";
import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  ExamPaperMiniDto,
  ExamSourceType,
  QuestionAnalysisDto, StarRatingRecordAuditDto,
  AuditUserLogStatus
} from "@/api/appService";
import {AuditBodyDto} from "@/utils/customDto";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";
import ChoiceItemAttachment from "@/components/ChoiceItemAttachment/index.vue";
import ExamPaperDetail from "@/views/train/trainRecord/component/examPaperDetail.vue";

@Component({
  name: "serviceOrganizationDtoDetail",
  components: {AuditBlock, ChoiceItemAttachment,
    ExamPaperDetail},
  filters: {},
})
export default class ServiceObjectDetail extends Vue {
  dataId?: number;
  isAudit = false;
  reportComment?: string = '';
  interviewComment?: string = '';
  detail: any = {
    starRating: {
      title: undefined,
      type: {
        displayName: undefined,
        displayOrder: undefined
      },
      typeId: undefined
    },
    volunteer: {
      surName: undefined,
      volunteerOrgCode: undefined,
      sex: undefined,
      level: undefined,
      totalHours: undefined,
      userExtend: {
        point: undefined
      },
      serviceCount: undefined,
      trainCount: undefined,
      activityCount: undefined
    },
    orgName: undefined
  };
  baseURL: any;
  loading = true;
  exam: ExamPaperMiniDto = {};
  analysisList: QuestionAnalysisDto[] = [];
  attachments: any = [];
  typeList: DataDictionaryDto[] = [{
    displayName: undefined
  }];
  evaluationTypeList: DataDictionaryDto[] = [];

  // hostType = AttachmentHostType.TrainRecord;


  created() {
    this.fetchDataDictionary()
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
      this.loadAttachments();

      if (this.$route.query.isAudit) {
        this.isAudit = true;
      }

    }
  }

  // 获取详情
  async fetchDetail() {
    await api.starRatingRecord
      .get({id: this.dataId})
      .then((res) => {
        // this.loading = false;
        this.detail = {...res};

        if (this.detail) {

          if (!this.isAudit) {
            (this as any).reportComment = res.reportComment;
            (this as any).interviewComment = res.interviewComment;
          }
          if (res.auditFlowId) {
            // (this.auditButtons as any).fetchCanAudit();
          }

          api.examQuestion.getLatestTrainPaperId({
            userId: this.detail.creatorUserId,
            trainId: this.detail.starRatingId,
            sourceType: ExamSourceType.StarRating
          }).then(res => {
            this.exam = res;
            if (this.exam && this.exam.id) {
              this.loading = true;
              this.getDetail();
            }
          });
        }

        console.log(res);
      });
    console.log(this.detail);
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({key: "VolunteerLevel", maxResultCount: 1000})
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items!;
      });

    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: 'EvaluationType',
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.evaluationTypeList = res!.items!;
      });
  }

  getDetail() {
    api.examQuestion.getQuestionAnalysisList({paperId: this.exam.id}).then(res => {
      this.loading = false;
      this.analysisList = res;
    })
  }

  completed() {
    this.isAudit = false
  }

  getSplit(answer: any) {
    console.log(answer.split(','))
    return answer.split(',')
  }

  getType(id: number) {
    if (this.evaluationTypeList.length > 0) {
      for (let i = 0; i < this.evaluationTypeList.length; i++) {
        if (this.evaluationTypeList[i].id == id) {
          return this.evaluationTypeList[i].displayOrder;
        }
      }
    }
  }

  getTypeName(id: number) {
    if (this.evaluationTypeList.length > 0) {
      for (let i = 0; i < this.evaluationTypeList.length; i++) {
        if (this.evaluationTypeList[i].id == id) {
          return this.evaluationTypeList[i].displayName;
        }
      }
    }
  }

  postComment(action: string) {
    api.starRatingRecord.growthReportAndInterviewReview({
      body: {
        reportComment: this.reportComment,
        interviewComment: this.interviewComment,
        hostId: this.detail.id,
        status: action === 'pass' ? AuditUserLogStatus.Pass : AuditUserLogStatus.Reject
      }
    })
  }

  async loadAttachments() {
    this.loading = true;
    await api.attachmentService
      .getAttachments({
        hostId: this.dataId!.toString(),
        hostType: AttachmentHostType.StarRatingRecord
      })
      .then((res) => {
        this.loading = false;
        this.attachments = res;
      });
    console.log(this.attachments);
  }

  get auditApi() {
    return {
      canAudit: api.starRatingRecord.canAudit,
      userAudit: api.starRatingRecord.audit
    } as AuditApi
  }

  handleDownload(item: any) {
    // api.attachment.download(item.id);
    if (this.isImage(item)) {
      window.open(item.url, "_blank");
    } else {
      const downloadUrl = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
      window.open(downloadUrl, "_blank");
    }
  }

  isImage(item: any) {
    if (!item.extension) {
      return false;
    }
    let ext = "";
    if (item.extension.substr(0, 1) === ".") ext = item.extension.substr(1);

    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext.toLowerCase());
  }


  setData() {
    this.auditParams.interviewComment = this.interviewComment;
  }

  setReportCommentData(value: string) {
    this.reportComment = value
    this.auditParams.reportComment = value;
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
