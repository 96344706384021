







































import {Component, Ref, Vue, Watch,} from "vue-property-decorator";

import api from "@/api";
import {DataDictionaryCreateOrUpdateDto, TrainDto, TrainStatus,} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  name: "selectTrainList",
  components: {PagedTableView},
})
export default class EditData extends Vue {
  @Ref() readonly dataForm!: ElForm;

  defaultData: DataDictionaryCreateOrUpdateDto = {
    key: "",
    displayName: "",
    parentId: 0,
    id: 0,
  };
  queryForm: any = {
    title: undefined,
    status: TrainStatus.Published,
  }
  show = false;
  form: DataDictionaryCreateOrUpdateDto = {...this.defaultData};

  trainIds: any = [];
  selectionList = [] as TrainDto[];

  get title() {
    if (this.dataId) {
      return `编辑 ${this.form.displayName}`;
    } else {
      return "新建";
    }
  }

  @Watch("show")
  async fetchData(params: any) {
    return await api.train.getAll(params);
  }

  handleSelectionChange(rows: TrainDto[]) {
    this.selectionList = [];
    this.selectionList = rows;
  }

  save() {
    this.show = false;
    this.$emit("selectRows", this.selectionList)
  }

  cancel() {
    this.show = false;
  }
}
