

































import {Component,Ref, Vue} from "vue-property-decorator";
import api from "@/api/index";
import SelectedList from "@/views/train/maintain/component/selectedList.vue";
import UnselectedList from "@/views/train/maintain/component/unselectedList.vue";
import {
  AttachmentHostType,
  AuditFlowScope,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  ExamSourceType
} from "@/api/appService";
import enumFilter from "@/mixins/filters/enums";

@Component({
  name: "FormDetailDetail",
  components: {
    UnselectedList,
    SelectedList
  },
  mixins: [enumFilter]
})
export default class FormDetailDetail extends Vue {

  private id = 0;
  private sourceType = 'StarRating'

  created() {
    this.id = parseInt(this.$route.params.id, 10);
    // this.fetchData();
  }

  get ExamSourceType() {
    return ExamSourceType.StarRating;
  }

  // fetchData() {
  //   let _this=this as any;
  //   api.train.get({id: this.id}).then((result: any) => {
  //     this.formDetail = result;
  //     //
  //     this.loading = false;
  //   });
  // }

  jumpQuestionList(){
    this.$router.push({
      name:'questionList',
    })
  }
}
