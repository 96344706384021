















































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import { ExamSourceType } from "@/api/appService";


@Component({
  name: "UnselectedList",
  components: {
    PagedTableView,
  },
})
export default class UnselectedList extends Vue {
  @Prop() trainId?: number;
  @Prop() sourceType?: string
  @Prop() ExamSourceType?: any

  queryForm = {
    question: undefined,
    questionType: undefined,
    trainId: undefined,
    sourceType: undefined
  };
  selectionList = [];

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = rows;
  }

  fetchData(params: any) {
    return api.examQuestion.getUnselectedQuestionsByTrain({
      ...params,
      question: this.queryForm.question,
      questionType: this.queryForm.questionType,
      trainId: this.trainId,
      sourceType: this.sourceType,
      ExamSourceType: this.ExamSourceType
    });
  }

  handleSelected(item: any) {
    api.examQuestion.trainQuestionSave({questionId: item.id,trainId:this.trainId}).then(res => {
      this.$message.success('操作成功');
      (this as any).$bus.$emit('refresh-data');
    });
  }

  // 批量添加
  handleBatchAdd() {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: "请至少选择一条要添加的试题",
      });
      return;
    }
    this.$confirm("确认批量添加试题吗?", "提示").then(async () => {
      let ids: any = [];
      this.selectionList.forEach((item: any) => {
        ids.push(item.id);
      });
      await api.examQuestion
        .trainQuestionBatchSave({body:{ questionIds: ids.join(','),trainId:this.trainId,sourceType:this.ExamSourceType }})
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    });
  }

}
